import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hls from 'hls.js';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Utils from 'functions/Utils'
import { withStyles} from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import PlayIcon from 'static/images/icons/play_branco.svg'
import PauseIcon from 'static/images/icons/pause_branco.svg'
import VolumeOnIcon from 'static/images/icons/volume_on_branco.svg'
import VolumeOffIcon from 'static/images/icons/volume_off_branco.svg'
import CircularProgress from '@material-ui/core/CircularProgress';
import Controller from './Controller';
import './Player.css'
import throttle from 'lodash/throttle';

const PlayerCircularProgress = withStyles((theme) => ({
    root: {
        color: "#fff",
        height: "8vh !important",
        width: "8vh !important",
    }

}))(CircularProgress);

const PlayerButton = withStyles((theme) => ({
    root: {
        padding: 0,
        width: "6vh",
        height: "6vh",
        marginLeft: 20,
        marginBottom: 20,
        marginTop: 20,
        bottom: 0,
        left: 0,
        color: theme.palette.getContrastText(grey[900]),
        backgroundColor: grey[900] + '99',
        '&:hover': {
            backgroundColor: grey[900],
        },
    },
  }))(IconButton);

  const LiveTiming = withStyles((theme) => ({
    root: {
        fontSize: "1.5vmin",
        color: "#fff",
        marginLeft: 5,
        fontFamily: "GalanoGrotesqueBold",
        cursor: "default",
        backgroundColor: grey[900] + '90',
        '&:hover': {
            backgroundColor: grey[900]+ '90',
        },
        
    },
  }))(Button);

  const LiveButtonOn = withStyles((theme) => ({
    root: {
        fontSize: "1.5vmin",
        color: "#fff",
        fontFamily: "GalanoGrotesqueBold",
        cursor: "default",
        backgroundColor: red[900] + '90',
        '&:hover': {
            backgroundColor: red[900]+ '90',
        },
    },
  }))(Button);

  const LiveButtonOff = withStyles((theme) => ({
    root: {
        fontSize: "1.5vmin",
        color: "#fff",
        fontFamily: "GalanoGrotesqueBold",
        backgroundColor: red[900] + '30',
        '&:hover': {
            backgroundColor: red[900] + '50',
        },
    },
  }))(Button);

class PlayerContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hlsConfig : {
                manifestLoadingMaxRetry: 60,
                enableWorker: false,
                //liveSyncDurationCount: 5,
                //liveDurationInfinity: false,
                autoStartLoad: true,
                //debug: false,
                //startPosition: -1,
                //startLevel: 0,
                //maxMaxBufferLength: 300,
            },
            play: false,
            mute: true,
            live: true,
            liveAction: true,
            buffer: true,
            timeRef: '00:00:00',
        };

        this.playerRef = React.createRef();
        this.handleToggleLive = this.handleToggleLive.bind(this);
        this.handleToggleMute = this.handleToggleMute.bind(this);
        this.handleTogglePlay = this.handleTogglePlay.bind(this);
        throttle(this.updateTimeRef = this.updateTimeRef.bind(this), 300);

        window.playerComponent = this;
    }

    updateTimeRef() {
        if (!Controller.controllerMark.controller ) {
            return;
        }

        
        if (Player.buffering || !window.playerControllerComponent) {
            return;
        }
        // TODO PRECISA REVER
        let offset = (Controller.controllerMark.controller.maxTime - Player.hls.media.currentTime) * 1000;
        let currentTimestamp = Controller.controllerMark.controller.maxTimestamp - offset;
        console.log(`Current time Player: ${Player.hls.media.currentTime} `);
        console.log(`Max time Player: ${Controller.controllerMark.controller.maxTime} `);
        console.log(`Offset Player: ${Controller.controllerMark.controller.maxTime - Player.hls.media.currentTime} `);
        console.log(`Current timestamp: ${currentTimestamp} `);
        let timeRef = new Date(currentTimestamp).toLocaleTimeString("pt-BR");
        window.playerControllerComponent.notifyUpdateTimeRef(timeRef);
        this.setState({timeRef: timeRef});
    }

    handleToggleLive() {
        this.setState({
            liveAction: true,
            live: true,
            buffer: true,
        }, () => {
            Player.live = true;
            window.controllerComponent.notifyChangeLiveStatus(true);
            Player.buffering = true;
        });
        

        const timer = setTimeout((obj) => {    
            //Player.hls.startLoad();
            //Player.hls.media.pause();
            Player.buffering = false;
            
            Player.hls.media.currentTime = isNaN(Player.hls.media.duration) ? -1 : Player.hls.media.duration - 8;
            let playPromise = Player.hls.media.play();
            playPromise.then(function() {
                obj.setState({
                    liveAction: true,
                }, () => {
                    clearTimeout(timer);
                    Player.live = true;
                    window.controllerComponent.notifyChangeLiveStatus(true);
                });
                if (!Player.hls.liveSyncPosition) {
                    // is VOD
                    let duration = parseInt(Player.hls.levels[0].details.totalduration);
                    Player.hls.media.currentTime = duration - Math.min(300, duration);
                }
            }).catch(function(error) {
                console.error(error);
            });
            /*playPromise.then(function() {
                obj.setState({
                    liveAction: true,
                });
                console.log('-2');
                Player.live = true;
                window.controllerComponent.notifyChangeLiveStatus(true);
              }).catch(function(error) {
                Player.hls.media.play();
                console.log('-3');
                Player.live = true;
                obj.setState({
                    liveAction: true,
                });
                window.controllerComponent.notifyChangeLiveStatus(true);
              });*/
              
        }, 4000, this);
        
        
        
        
    }

    handleToggleMute(event) {
        Player.hls.media.muted = !this.state.mute;
        this.setState({
            mute: !this.state.mute,
        })
    }

    handleTogglePlay(event) {
        this.state.play ? Player.hls.media.play() : Player.hls.media.pause();
    }

    componentWillUnmount() {

    }
    
    componentDidUpdate() {
        
    }

    componentDidMount() {
        Player.live = true;
        window.controllerComponent.notifyChangeLiveStatus(true);

        const obj = this;
        let hls = null;

        function _initPlayer(autoplay) {
            if (hls != null) {
                hls.destroy();
            }

            const newHls = new Hls(obj.state.hlsConfig);
            newHls.attachMedia(obj.playerRef.current);
            newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
                let subscriber = JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber'));

                newHls.loadSource(subscriber.hls);

                newHls.on(Hls.Events.MANIFEST_PARSED, () => {
                    if (autoplay) {
                        console.log('PARSED');
                    }
                });
            });
            
            newHls.on(Hls.Events.BUFFER_RESET, function (event, data) {
                console.log("BUFFER_RESET");
                newHls.startLoad();
            });

            newHls.on(Hls.Events.BUFFER_CREATED, function (event, data) {
                console.log("BUFFER_CREATED");
                if (!Player.live) {
                    return;
                }
                obj.playerRef.current.muted = true;
                obj.handleToggleLive();
                
                //obj.playerRef.current.muted = true;
                
                /*
                let playPromise = obj.playerRef.current.play();
                playPromise.then(function() {
                    obj.playerRef.current.muted = true;
                    let currentTime;
                    if (!Player.hls.liveSyncPosition) {
                        // is VOD
                        let duration = parseInt(Player.hls.levels[0].details.totalduration);
                        currentTime = duration - Math.min(300, duration);
                    } else {
                        //is LIVE
                        currentTime = parseInt(Player.hls.levels[0].details.totalduration) - 12;
                    }

                    
                    Player.hls.media.currentTime = currentTime;
                }).catch(function(error) {
                    console.error(error);
                });
                */

                
            });

            newHls.on(Hls.Events.BUFFER_FLUSHING, function (event, data) {
                console.log("BUFFER_FLUSHING");
            });
            
            newHls.on(Hls.Events.BUFFER_FLUSHED, function (event, data) {
                console.log("BUFFER_FLUSHED");
            });

            newHls.on(Hls.Events.BUFFER_EOS, function (event, data) {
                console.log("BUFFER_EOS");
                localStorage.setItem('@crabber/Player.liveStatus', 'Sessão encerrada');
                /*if (Player.live) {
                    Player.live = false;
                    window.controllerComponent.notifyChangeLiveStatus(false);
                }*/
            });

            newHls.on(Hls.ErrorDetails.BUFFER_STALLED_ERROR, function (event, data) {
                console.log("BUFFER_STALLED_ERROR");
                localStorage.setItem('@crabber/Player.liveStatus', 'Sessão encerrada');
                if (Player.live) {
                    Player.live = false;
                    window.controllerComponent.notifyChangeLiveStatus(false);
                }
            });

            newHls.on(Hls.ErrorDetails.BUFFER_NUDGE_ON_STALL, function (event, data) {
                console.log("BUFFER_NUDGE_ON_STALL");
                localStorage.setItem('@crabber/Player.liveStatus', 'Sessão encerrada');
                if (Player.live) {
                    Player.live = false;
                    window.controllerComponent.notifyChangeLiveStatus(false);
                }
            });

            newHls.on(Hls.ErrorDetails.BUFFER_SEEK_OVER_HOLE, function (event, data) {
                console.log("BUFFER_SEEK_OVER_HOLE");
                localStorage.setItem('@crabber/Player.liveStatus', 'Sessão encerrada');
                if (Player.live) {
                    Player.live = false;
                    window.controllerComponent.notifyChangeLiveStatus(false);
                }
            });

            newHls.on(Hls.ErrorDetails.BUFFER_APPENDING_ERROR, function (event, data) {
                console.log("BUFFER_APPENDING_ERROR");
                localStorage.setItem('@crabber/Player.liveStatus', 'Sessão encerrada');
                if (Player.live) {
                    Player.live = false;
                    window.controllerComponent.notifyChangeLiveStatus(false);
                }
            });

            newHls.on(Hls.ErrorDetails.BUFFER_APPEND_ERROR, function (event, data) {
                console.log("BUFFER_APPEND_ERROR");
                localStorage.setItem('@crabber/Player.liveStatus', 'Sessão encerrada');
                if (Player.live) {
                    Player.live = false;
                    window.controllerComponent.notifyChangeLiveStatus(false);
                }
            });

            newHls.on(Hls.Events.ERROR, function (event, data) {
                if (data.fatal) {
                    switch(data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            //console.log("concluída 3");
                            localStorage.setItem('@crabber/Player.liveStatus', 'Estabelecendo conexão');
                            setTimeout(
                                () => newHls.recoverMediaError(),          
                                4000
                              );
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            //console.log("concluída 4");
                            localStorage.setItem('@crabber/Player.liveStatus', 'Estabelecendo conexão');
                            newHls.recoverMediaError();
                            break;
                        default:
                            _initPlayer(true);
                            break;
                    }
                }
            });

            newHls.on(Hls.Events.FRAG_PARSING_INIT_SEGMENT, function (event, data) {
                //console.log(newHls.liveSyncPosition);
                if (newHls.liveSyncPosition === undefined) {
                    //console.log("concluída 5");
                    localStorage.setItem('@crabber/Player.liveStatus', 'Sessão encerrada');
                    Player.live = false;
                    window.controllerComponent.notifyChangeLiveStatus(false);
                } else {
                    //console.log("gravando 1");
                    let minutes = localStorage.getItem('@crabber/Ingest.minutes');
                    let message = minutes > 0 ? 'Gravando sessão há cerca de ' + Utils.humanMinutes(minutes) : 'Gravando sessão';
                    localStorage.setItem('@crabber/Player.liveStatus', message);
                    if (!Player.live) {
                        Player.live = true;
                        window.controllerComponent.notifyChangeLiveStatus(true);
                    }
                }
            });

            obj.playerRef.current.addEventListener("play", function(event) {
                obj.setState({
                    buffer: true,
                    play: false,
                });
            });

            obj.playerRef.current.addEventListener("playing", function(event) {
                let liveAction = obj.state.liveAction;
                Player.live = obj.state.live || liveAction;
                window.controllerComponent.notifyChangeLiveStatus(obj.state.live || liveAction);
                obj.setState({
                    buffer: false,
                    play: false,
                    live: liveAction,
                    liveAction: false,
                });
                
                
                if (Player.hls.liveSyncPosition === undefined) {
                    console.log("ENDED");
                    localStorage.setItem('@crabber/Player.liveStatus', 'Sessão encerrada');
                }
            });

            obj.playerRef.current.addEventListener("ended", function(event) {
                console.log("ENDED");
                localStorage.setItem('@crabber/Player.liveStatus', 'Sessão encerrada');
                Player.live = false;
                window.controllerComponent.notifyChangeLiveStatus(false);
            });

            obj.playerRef.current.addEventListener("pause", function(event) {
                obj.setState({
                    play: true,
                    live: false,
                    liveAction: false,
                }, ()=> {
                    Player.live = false;
                    window.controllerComponent.notifyChangeLiveStatus(false);
                });
            });

            obj.playerRef.current.addEventListener("seeked", function(event) {
                console.log("SEEKED", event);
                
            });

            obj.playerRef.current.addEventListener("durationchange", function(event) { 
                
            });

            obj.playerRef.current.addEventListener("timeupdate", function(event) { 
                obj.updateTimeRef();
            });

            hls = newHls;
            Player.hls = newHls;
        }

        let publisher = JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber'));
        if(publisher) {
            _initPlayer(true);
            localStorage.setItem('@crabber/Player.liveStatus', 'Carregando sinal');
        }
        
        return () => {
            if (hls != null) {
                hls.destroy();
            }
        };

        
    }

    render() {
        return (
            <div className='player-container'>
                <Grid container alignContent='center' alignItems='flex-end' direction='row' justify='flex-start' className='player-grid-container' >
                    <Grid item xs={8} >
                        <div className="video-container">
                            {this.state.buffer && 
                            <div className="buffer">
                                 <PlayerCircularProgress />
                            </div>
                            }
                            <video
                                className="video-canvas"
                                ref={this.playerRef}
                                autoPlay={true}
                                controls={false}
                                muted={true}
                            />
                                <div className="controls">
                                    <div className="video-sub-controls">
                                        <PlayerButton onClick={this.handleTogglePlay}>
                                            {this.state.play &&  <img src={PlayIcon} alt='play' className='player-icon'/>}
                                            {!this.state.play &&  <img src={PauseIcon} alt='pause' className='player-icon'/>}
                                        </PlayerButton>
                                        <PlayerButton onClick={this.handleToggleMute}>
                                            {this.state.mute && <img src={VolumeOffIcon} alt='volume off' className='player-icon'/>}
                                            {!this.state.mute && <img src={VolumeOnIcon} alt='volume on' className='player-icon'/>}
                                        </PlayerButton>
                                    </div>
                                    <div className="video-sub-controls">
                                        {(Player.live && Player.hls && Player.hls.liveSyncPosition) ? (<LiveButtonOn variant="contained" color="primary" disableRipple className='player-live-icon'>AO VIVO</LiveButtonOn>) : (<div></div>)}
                                        {(!Player.live && Player.hls &&  Player.hls.liveSyncPosition) ? (<LiveButtonOff variant="contained" color="primary" className='player-live-icon' onClick={this.handleToggleLive}>VOLTAR AO VIVO</LiveButtonOff>) : (<div></div>)}
                                        <LiveTiming variant="contained" color="primary" className='player-live-icon'>{this.state.timeRef}</LiveTiming>
                                    </div>
                                    <div className="video-sub-controls videosub-controls-fake"></div>
                                </div>
                        </div>
                    </Grid>
                    <Grid item xs={4} >
                    </Grid>
                </Grid>
            </div>
        )
    }
}


function Player(props) {

    return (
        <div >
            <PlayerContainer />
        </div>
    )

}

export default Player